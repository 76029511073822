<template>
  <div>
    <b-avatar
      style="margin-top: 5px; font-size: calc(1.5rem) !important"
      :text="co_shortName"
      :size="p_size"
      variant="light"
    ></b-avatar>
  </div>
</template>

<script>
export default {
  name: "cmp-subjectavatar",
  props: {
    p_name: {
      type: String,
      default: "",
    },
    p_size: {
      type: String,
      default: "6rem",
    },
  },
  computed: {
    co_shortName: function () {
      if (this.p_name != null && this.p_name.trim().length > 0) {
        let l_split = this.p_name.split(" ");
        if (l_split.length == 1) {
          return l_split[0].substring(0, Math.min(2, l_split[0].length));
        } else {
          return l_split[0].substring(0, 1) + l_split[1].substring(0, 1);
        }
      }
      return this.p_email.substring(0, Math.min(2, this.p_email.length));
    },
  },
  data() {
    return {
      d_hasImageError: null,
    };
  },
  watch: {
    p_avatar: function (p_value) {
      if (p_value != null && p_value.trim().length > 0)
        this.d_hasImageError = false;
    },
  },
  methods: {
    d_onImageError(e) {
      this.d_hasImageError = true;
    },
  },
};
</script>

<style>
span.b-avatar-text {
  /* background: rgb(130, 191, 111);
  background: linear-gradient(90deg, rgba(130, 191, 111, 1)); */
  color: #83bf6f;
}
</style>