<template>
  <div>
    <div class="row filter-parent-main">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <!-- Row -->
        <div class="row align-items-center mb-3">
          <div class="col-12">
            <span style="position: absolute; top: 15px">
              We found <strong>{{ co_foundSubjectscount }}</strong> subjects for
              you
            </span>
            <button
              style="float: right; color: #647b9c"
              class="btn btn-light"
              v-b-toggle.sidebar-backdrop
            >
              Filter<b-icon-arrow-right-circle-fill
                font-scale="1"
                style="margin-left: 5px"
              ></b-icon-arrow-right-circle-fill>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <b-sidebar
          id="sidebar-backdrop"
          width="320px"
          class="text-center"
          backdrop-variant="transparent"
          backdrop
          shadow
          bg-variant="white"
        >
          <div class="filt-head">
            <h4 class="filt-first">Advanced Options</h4>
          </div>
          <div class="show-hide-sidebar">
            <!-- Find New Property -->
            <div class="sidebar-widgets">
              <!-- Search Form -->
              <!-- <form class="form-inline addons mb-3">
                <input
                  class="form-control"
                  type="search"
                  placeholder="Search Subjects"
                  aria-label="Search"
                />
                <button class="btn my-2 my-sm-0" type="submit">
                  <i class="ti-search"></i>
                </button>
              </form> -->
              <h4 class="side-title">Education</h4>
              <div id="main2" style="margin-bottom: 20px; min-height: 130px">
                <button
                  @submit.prevent
                  @click="m_onEducationChanged('s')"
                  :class="
                    d_education != 's'
                      ? 'btn btn-outline-theme btn-education-sidebar'
                      : 'btn btn-theme btn-education-sidebar'
                  "
                >
                  School
                </button>

                <button
                  @submit.prevent
                  @click="m_onEducationChanged('u')"
                  :class="
                    d_education != 'u'
                      ? 'btn btn-outline-theme btn-education-sidebar'
                      : 'btn btn-theme btn-education-sidebar'
                  "
                >
                  University
                </button>

                <button
                  @submit.prevent
                  @click="m_onEducationChanged('l')"
                  :class="
                    d_education != 'l'
                      ? 'btn btn-outline-theme btn-education-sidebar'
                      : 'btn btn-theme btn-education-sidebar'
                  "
                >
                  Languages
                </button>

                <button
                  @submit.prevent
                  @click="m_onEducationChanged('o')"
                  :class="
                    d_education != 'o'
                      ? 'btn btn-outline-theme btn-education-sidebar'
                      : 'btn btn-theme btn-education-sidebar'
                  "
                >
                  Others
                </button>
              </div>
              <div v-if="this.d_education != 'a'">
                <h4 class="side-title">Categories</h4>
                <b-form-group class="text-left px-2">
                  <b-form-checkbox
                    v-model="d_allCategoriesSelected"
                    value="all"
                    :indeterminate="d_indeterminate"
                    @change="m_toggleAll"
                  >
                    All ({{ co_allCount }})
                  </b-form-checkbox>
                  <b-form-checkbox-group
                    v-model="d_category"
                    name="filterCategories"
                    stacked
                  >
                    <b-form-checkbox
                      v-for="(p_value, p_key) in gt_categories"
                      :key="p_key"
                      @change="m_onCategoryChanged"
                      :value="p_key"
                    >
                      {{ p_value.en }} ({{ p_value.count }})
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
          </div>
        </b-sidebar>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "cmp-subjectfilter",

  computed: {
    ...mapGetters("md_fire", ["gt_categories", "gt_filteredSubjects"]),
    co_allCount: function () {
      if (this.gt_categories == null) return 0;
      let l_count = 0;
      Object.entries(this.gt_categories).forEach((p_element) => {
        l_count += p_element[1].count * 1;
      });
      return l_count;
    },
    co_foundSubjectscount: function () {
      if (!this.gt_filteredSubjects) return "0";
      return Object.keys(this.gt_filteredSubjects).length;
    },
  },
  data() {
    return {
      d_education: "a",
      d_category: [],
      d_fromfilterBtn: false,
      d_allCategoriesSelected: "all",
      d_indeterminate: false,
    };
  },
  async mounted() {
    this.d_education = this.$store.state.md_fire.st_educationSubjects;
    let category = this.$store.state.md_fire.st_category;
    const l_categories = await this.gt_categories;
    let l_ca =
      category == "all" && l_categories
        ? Object.keys(l_categories)
        : category.slice();
    await this.m_onCategoryChanged(l_ca);
  },
  methods: {
    m_toggleAll(p_checked) {
      this.d_category = p_checked ? Object.keys(this.gt_categories) : [];
      this.$store.commit("md_fire/mt_setCategory", this.d_category);
    },
    m_openNav(p_fromBtn) {
      this.d_fromfilterBtn = p_fromBtn;
      document.getElementById("filter-sidebar").style.width = "320px";
    },
    m_onCategoryClicked() {
      alert("onCategory Clicked");
    },

    m_onEducationChanged(p_education) {
      if (this.d_education == p_education) {
        this.d_education = "a";
        p_education = "a";
      }
      this.d_education = p_education;
      this.$store.commit("md_fire/mt_setSubjectsEducation", p_education);
      this.d_allCategoriesSelected = "all";
      this.d_indeterminate = false;
      this.d_category = Object.keys(this.gt_categories);
      this.$store.commit("md_fire/mt_setCategory", this.d_category);
    },
    async m_onCategoryChanged(p_checkedCategories) {
      const l_categories = await this.gt_categories;
      if (l_categories) {
        let l_allCategories = Object.keys(l_categories).slice();
        let l_selectedCategories =
          p_checkedCategories[0] == "all"
            ? l_allCategories
            : p_checkedCategories;
        if (!(await this.$localforage.getItem("fs_fromCurriculum"))) {
          if (_.isEqual(l_allCategories.sort(), l_selectedCategories.sort())) {
            this.d_allCategoriesSelected = "all";
            this.d_category = l_allCategories;
            this.d_indeterminate = false;
          } else if (l_selectedCategories.length == 0) {
            this.d_allCategoriesSelected = "";
            this.d_indeterminate = false;
          } else {
            this.d_allCategoriesSelected = "";
            this.d_category = l_selectedCategories;
            this.d_indeterminate = true;
          }
        } else {
          await this.$localforage.setItem("fs_fromCurriculum", false);
          const l_fromCategory = await this.$localforage.getItem(
            "fs_filterCategory"
          );
          await this.$localforage.setItem("fs_filterCategory", null);
          this.d_category = l_fromCategory ? [l_fromCategory] : l_allCategories;
          if (l_fromCategory) {
            this.d_allCategoriesSelected = "";
            this.d_indeterminate = true;
          }
        }
        this.$store.commit("md_fire/mt_setCategory", this.d_category);
      }
    },
    async m_searchSubject() {
      this.$store.commit("md_fire/mt_setLoading", true);

      setTimeout(() => {
        this.$store.commit("md_fire/mt_setLoading", false);
      }, 750);
    },
  },
};
</script>

<style>
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #82bf6f !important;
  background-color: #82bf6f;
  box-shadow: none;
}
.custom-control-label::before {
  background-color: #82bf6f;
}
.btn-education-sidebar {
  width: 136px;
  height: 51.59px;
  margin: 2px;
}
.custom-control-label {
  color: #647b9c;
  font-family: inherit;
  font-weight: 100;
}

.filter-parent-main {
  margin-top: 30px;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .filter-parent-main {
    margin-top: 20px;
  }
}
</style>