<template>
  <div class="col-lg-4 col-md-6">
    <div class="education-block-grid style-1001">
      <div
        class="education-block-thumb n-shadow text-center"
        style="width: 100%"
      >
        <!-- <img :src="m_getImageURL()" class="img-fluid" alt="" />
        <div class="text-overlay">{{ co_getEducationName }}</div> -->
        <cmp-subjectavatar
          :p_name="p_data.en"
          p_size="15rem"
        ></cmp-subjectavatar>
      </div>

      <div class="education-block-body text-center">
        <h3 class="text-center" style="margin-bottom: 15px; min-height: 70px">
          <span>{{ p_data.en }}</span>
        </h3>
      </div>

      <a
        style="width: 100%; margin-bottom: 0"
        class="btn btn-light light disabled"
        v-if="p_accountStatus == 'B'"
        disabled
        >Account Banned
        <b-icon-eye style="margin-left: 10px"></b-icon-eye>
      </a>
      <b-button
        @click="m_pickTutor"
        type="button"
        class="btn btn-light"
        v-else-if="p_role == 'student'"
        style="width: 100%; margin-bottom: 0"
        >Pick a Tutor
        <b-icon-app-indicator></b-icon-app-indicator>
      </b-button>
      <b-button
        @click="m_applyToSubject"
        type="button"
        class="btn btn-light"
        style="width: 100%; margin-bottom: 0"
        v-else-if="p_role == 'tutor' && !co_isAlreadyEnrolled"
        :disabled="co_isAlreadyEnrolled"
        >Apply
        <b-icon-cursor-fill style="margin-left: 10px"></b-icon-cursor-fill
      ></b-button>
      <a
        style="width: 100%; margin-bottom: 0"
        class="btn btn-light light disabled"
        v-else-if="p_role == 'tutor'"
        disabled
        >Applied
        <b-icon-check-circle style="margin-left: 10px"></b-icon-check-circle>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import c_subjectAvatar from "./cmp-subjectavatar.vue";
export default {
  components: { "cmp-subjectavatar": c_subjectAvatar },
  name: "cmp-subjectcard",
  computed: {
    co_isAlreadyEnrolled: function () {
      let l_value = false;
      if (
        this.p_role == "tutor" &&
        this.$store.state.md_fire.st_tutorSubjects.find(
          (p_element) => p_element.id == this.p_data.key
        ) != null
      ) {
        l_value = true;
      }
      return l_value;
    },
    co_tutorsCount: function () {
      if (!this.p_data || !this.p_data.tutors) return "0";
      if (
        this.$store.state.md_fire.st_education != "s" ||
        !this.$store.state.md_fire.st_curriculum
      )
        return Object.keys(this.p_data.tutors).length;

      let l_count = 0;
      Object.keys(this.p_data.tutors).forEach((p_element) => {
        if (
          this.p_data.tutors[p_element].curriculums != null &&
          this.p_data.tutors[p_element].curriculums
            .split(",")
            .indexOf(this.$store.state.md_fire.st_curriculum) != -1
        )
          l_count++;
      });
      return l_count;
    },
    co_getEducationName: function () {
      return this.p_educationLevels[this.p_data.education];
    },
  },
  props: {
    p_data: {
      type: Object,
    },
    p_role: { type: String },
    p_accountStatus: { type: String },
    p_educationLevels: {
      type: Object,
    },
  },
  methods: {
    m_getImageURL() {
      let l_url = "/img/971TutorsImages/curriculums/default.jpeg";
      let l_splitLength = this.p_data.key.split("_").length;
      if (l_splitLength == 3) {
        l_url =
          "/img/971TutorsImages/categories/" +
          this.p_data.key.split("_")[1] +
          ".jpeg";
      }
      return l_url;
    },
    m_pickTutor() {
      this.$store.commit("md_fire/mt_setSubject", this.p_data);
      this.$store.commit("md_fire/mt_setLoading", true);
      this.$router.push({
        name: "subject",
        params: {
          p_id: this.p_data.key,
        },
      });
    },
    m_applyToSubject() {
      this.$store.commit("md_fire/mt_setSubject", this.p_data);
      this.$store.commit("md_fire/mt_setLoading", true);
      this.$router.push({
        name: "tutorApplySubject",
      });
    },
  },
};
</script>

<style scoped>
.text-overlay {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 1em;
  background-color: #83bf6f;
  color: white;
  font-size: 1.2em;
  border-top-left-radius: 4px;
}
</style>