<template>
  <section id="main-wrapper" style="min-height: 97vh; padding-bottom: 0">
    <div class="container">
      <section class="page-title">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="breadcrumbs-wrap">
                <h1 class="breadcrumb-title">{{ this.co_getPageName }}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <cmp-subjectfilter ref="filter" />
      <div class="row" v-if="filterHasSubjects && d_isMobile">
        <cmp-mobilesubjectcard
          v-for="subject in gt_filteredSubjects"
          :key="subject.id"
          :p_data="subject"
          :p_role="gt_userRole"
          :p_accountStatus="gt_userStatus"
          :p_educationLevels="d_educationLevels"
        />
      </div>
      <div class="row" v-if="filterHasSubjects && !d_isMobile">
        <cmp-subjectcard
          v-for="subject in gt_filteredSubjects"
          :key="subject.id"
          :p_data="subject"
          :p_role="gt_userRole"
          :p_accountStatus="gt_userStatus"
          :p_educationLevels="d_educationLevels"
        />
      </div>

      <div class="row mt-5" v-else-if="!filterHasSubjects">
        <div class="col-12 text-center">
          <p>No Subjects were found based on your search</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import c_subjectFilter from "../cmp-components/cmp-subjectfilter.vue";
import c_subjectCard from "../cmp-components/cmp-subjectcard.vue";
import c_mobilesubjectCard from "../cmp-components/cmp-mobilesubjectcard.vue";
export default {
  name: "cmp-subjects",
  components: {
    "cmp-subjectfilter": c_subjectFilter,
    "cmp-subjectcard": c_subjectCard,
    "cmp-mobilesubjectcard": c_mobilesubjectCard,
  },
  data: () => ({
    d_pageName: "",
    d_educationLevels: {
      a: "All",
      s: "School",
      u: "University",
      l: "Languages",
      o: "Others",
    },
    d_isMobile: false,
  }),
  computed: {
    ...mapGetters("md_fire", [
      "gt_filteredSubjects",
      "gt_userRole",
      "gt_userStatus",
    ]),
    ...mapState({
      st_educationSubjects: (state) => state.md_fire.st_educationSubjects,
    }),

    filterHasSubjects: function () {
      return (
        this.gt_filteredSubjects &&
        Object.keys(this.gt_filteredSubjects).length > 0
      );
    },
    co_getPageName: function () {
      return (
        this.d_educationLevels[this.st_educationSubjects].replace(/s$/, "") +
        " subjects"
      );
    },
    co_getEducationLevels: function () {
      return this.d_educationLevels;
    },
  },
  mounted() {
    this.$nextTick().then(() => {
      this.onResize();
      window.addEventListener("resize", this.onResize, { passive: true });
      setTimeout(async () => {
        this.$store.commit("md_fire/mt_setLoading", false);
      }, 500);
    });
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },
  methods: {
    onResize() {
      this.d_isMobile = window.innerWidth < 600;
    },
  },
};
</script>