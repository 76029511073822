<template>
  <div
    class="col-12"
    style="
      vertical-align: center;
      border-top: 1px solid #e9edf3;
      padding-bottom: 4px;
    "
    @click="m_onRowClicked"
  >
    <cmp-subjectavatar
      style="float: left"
      :p_name="p_data.en"
      p_size="4rem"
    ></cmp-subjectavatar>
    <span
      style="
        float: left;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        max-width: 300px;
        margin-left: 5px;
      "
      >{{ p_data.en }}</span
    >
    <button
      v-on:click.stop
      type="button"
      class="btn p-0 btn-share"
      style="
        margin-bottom: 0;
        float: right;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      "
      aria-label="Right Align"
      @click="m_share()"
    >
      <b-icon-share-fill></b-icon-share-fill>
    </button>
    <!-- <button
      class="btn btn-theme"
      style="padding: 7px; float: right; margin-top: 15px"
    >
      Tutors
    </button> -->
  </div>
</template>

<script>
import { Capacitor, Plugins } from "@capacitor/core";
const { Share } = Plugins;
import c_subjectAvatar from "./cmp-subjectavatar.vue";
import { m_messagePopup } from "../../lib-core/lib-helpers/lib-utils.js";
export default {
  components: { "cmp-subjectavatar": c_subjectAvatar },
  name: "cmp-subjectcard",
  computed: {
    co_isAlreadyEnrolled: function () {
      let l_value = false;
      if (
        this.p_role == "tutor" &&
        this.$store.state.md_fire.st_tutorSubjects.find(
          (p_element) => p_element.id == this.p_data.key
        ) != null
      ) {
        l_value = true;
      }
      return l_value;
    },
    co_tutorsCount: function () {
      if (!this.p_data || !this.p_data.tutors) return "0";
      if (
        this.$store.state.md_fire.st_education != "s" ||
        !this.$store.state.md_fire.st_curriculum
      )
        return Object.keys(this.p_data.tutors).length;

      let l_count = 0;
      Object.keys(this.p_data.tutors).forEach((p_element) => {
        if (
          this.p_data.tutors[p_element].curriculums != null &&
          this.p_data.tutors[p_element].curriculums
            .split(",")
            .indexOf(this.$store.state.md_fire.st_curriculum) != -1
        )
          l_count++;
      });
      return l_count;
    },
    co_getEducationName: function () {
      return this.p_educationLevels[this.p_data.education];
    },
  },
  props: {
    p_data: {
      type: Object,
    },
    p_role: { type: String },
    p_accountStatus: { type: String },
    p_educationLevels: {
      type: Object,
    },
  },
  methods: {
    async m_share() {
      const url =
        "https://971tutors.com" +
        this.$router.resolve({
          name: "subject",
          params: {
            p_id: this.p_data.key,
          },
        }).href;
      await Share.share({
        title: "Study " + this.p_data.en + " with 971Tutors",
        text: "Study " + this.p_data.en + " with 971Tutors",
        url: url != null ? url : "",
        dialogTitle: "Share subject",
      });
    },
    m_onRowClicked() {
      if (this.p_accountStatus == "B") {
        m_messagePopup(this, "Banned", "Your Account has been banned");
        return;
      }
      if (this.p_role == "tutor" && this.co_isAlreadyEnrolled) {
        m_messagePopup(
          this,
          "Applied",
          "You have already applied for this subject"
        );
        return;
      }
      if (this.p_role == "student") {
        this.m_pickTutor();
        return;
      }
      if (this.p_role == "tutor" && !this.co_isAlreadyEnrolled) {
        this.m_applyToSubject();
        return;
      }
    },

    m_pickTutor() {
      this.$store.commit("md_fire/mt_setSubject", this.p_data);
      this.$store.commit("md_fire/mt_setLoading", true);
      this.$router.push({
        name: "subject",
        params: {
          p_id: this.p_data.key,
        },
      });
    },
    m_applyToSubject() {
      this.$store.commit("md_fire/mt_setSubject", this.p_data);
      this.$store.commit("md_fire/mt_setLoading", true);
      this.$router.push({
        name: "tutorApplySubject",
      });
    },
  },
};
</script>

<style scoped>
.text-overlay {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 1em;
  background-color: #83bf6f;
  color: white;
  font-size: 1.2em;
  border-top-left-radius: 4px;
}
.btn-share {
  color: grey;
}
.btn-share:hover {
  color: darkgrey;
}
</style>